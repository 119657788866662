import { AutoSuggestResponse } from '../components/inputs/input-autosuggest/input-autosuggest.interface';
import { CheckinHttpClient } from 'src/app/shared/services/http/http-client.service';
import { EnvService } from './env.service';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { SelectDataOptionsList } from '../common.interfaces';
import { map, tap, catchError } from 'rxjs/operators';
import { phoneCodes } from '../components/inputs/input-phone/input-phone.codes';
import { InjectorService } from 'src/app/shared/services/injector/injector.service';

@Injectable()
export class GeoService {
  baseUrl: string;

  countriesValue: SelectDataOptionsList;
  countriesRequest: Observable<SelectDataOptionsList>;
  prefixesRequest: Observable<SelectDataOptionsList>;

  private http: CheckinHttpClient;

  constructor(private env: EnvService) {
    this.http = InjectorService.get().get(CheckinHttpClient);
    this.baseUrl = `${this.env.endpoint}/v1/`;
  }

  countries(): Observable<SelectDataOptionsList> {
    if (this.countriesValue) {
      return of(this.countriesValue);
    }

    if (!this.countriesRequest) {
      this.countriesRequest = this._getCountriesRequestWithCb((countries) => countries);
    }

    return this.countriesRequest;
  }

  public prefixes(): Observable<SelectDataOptionsList> {
    if (this.countriesValue) {
      return of(this._parsePrefixes());
    }

    if (!this.prefixesRequest) {
      this.prefixesRequest = this._getCountriesRequestWithCb(() => this._parsePrefixes());
    }

    return this.prefixesRequest;
  }

  private _getCountriesRequestWithCb(cb: (countries: SelectDataOptionsList) => SelectDataOptionsList): Observable<SelectDataOptionsList> {
    const url = `${this.baseUrl}countries`;

    return this.http.get(url).pipe(
      tap((res: SelectDataOptionsList) => {
        this.countriesValue = this.sortCountriesByPopular(res);
      }),
      map((res: SelectDataOptionsList) => cb(this.countriesValue)),
      catchError((error: any) => {
        throw new Error(error || 'Server error');
      })
    );
  }

  private _parsePrefixes(): SelectDataOptionsList {
    return Object.keys(this.countriesValue).map((index) => {
      const { code, name, disabled } = this.countriesValue[index];

      return { code: phoneCodes[code] ? phoneCodes[code] : code, name, disabled };
    });
  }

  private sortCountriesByPopular(countries: SelectDataOptionsList) {
    const popularCodes = { 1: true, 2: true, 5: true, 6: true, 8: true, 23: true, 26: true };
    const { popular, rest } = countries.reduce((acc, c) => {
      const isPopular = popularCodes[c.code as number];

      acc[isPopular ? 'popular' : 'rest'].push(c);

      return acc;
    }, { popular: [], rest: [] });

    return [
      ...popular,
      { code: '', name: '─────────────────────', disabled: true },
      ...rest
    ];
  }

  getEntityByKey(value: string, entity: string, criteriaCondition: string) {
    // tslint:disable-next-line:max-line-length
    const url = (criteriaCondition) ? `${this.baseUrl}georegions?query=${value}&fields=${entity}&criteria=${criteriaCondition}` : `${this.baseUrl}georegions?query=${value}&fields=${entity}`;

    return this.http.get(url).pipe(
      map((res: { [key: string]: AutoSuggestResponse }) => res[entity].slice(0, 5)),
      catchError((error: any) => {
        return of([]);
      })
    );
  }
}
