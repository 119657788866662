export enum EControls {
  acceptCommercialInfo = 'acceptCommercialInfo',
  clientBirthDate = 'clientBirthDate',
  clientBirthPlace = 'clientBirthPlace',
  clientCity = 'clientCity',
  clientCountry = 'clientCountry',
  clientEmail = 'clientEmail',
  clientFirstSurname = 'clientFirstSurname',
  clientGender = 'clientGender',
  clientIdIssuedAt = 'clientIdIssuedAt',
  clientIdIssuedOn = 'clientIdIssuedOn',
  clientIdNumber = 'clientIdNumber',
  clientIdType = 'clientIdType',
  clientName = 'clientName',
  clientNationality = 'clientNationality',
  clientPhoneNumber = 'clientPhoneNumber',
  clientPostalCode = 'clientPostalCode',
  clientProvince = 'clientProvince',
  clientSecondSurname = 'clientSecondSurname',
  clientStreet = 'clientStreet',
  clientTaxId = 'clientTaxId',
  confirmFields = 'confirmFields',
  typeOfGuest = 'typeOfGuest',
  clientIdValidityDate = 'clientIdValidityDate',
  clientSupportNumber = 'clientSupportNumber',
  clientKinship = 'clientKinship'
}

export enum EControlsType {
  AUTOSUGGEST = 'AUTOSUGGEST',
  CHECK = 'CHECK',
  DATE = 'DATE',
  DOCUMENT_TYPE = 'DOCUMENT_TYPE',
  PHONE = 'PHONE',
  SELECT = 'SELECT',
  TEXT = 'TEXT'
}
