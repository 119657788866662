import * as _ from 'lodash';
import { ProjectConfig as ICheckoutConfig } from 'src/app/shared/classes/projects/project-config.class';
import { IConfig } from 'src/app/app.interfaces';
import { PaymentsList, PaymentsListItem } from 'src/app/shared/modules/sidebar/booking-detail/payments-list/payments-list.interfaces';

export class CheckoutConfig extends ICheckoutConfig {
  public config: {
    selectedPayment: PaymentsListItem;
    sourceApp: string;
    waysOfPay: {
      numberOfMethods: number;
      typesIncludes: string[];
    }
  };

  constructor(params: IConfig['extraConfig'], payments: PaymentsList) {
    super(params);
    this.setConfig(params, payments);
  }

  private setConfig(params: IConfig['extraConfig'], payments: PaymentsList): void {
    const selectedPayment: PaymentsListItem = [...(_.get(payments, 'deposit', [])), ...(_.get(payments, 'paymentsDetail', []))]
      .find((p: PaymentsListItem) => p.selected);

    this.config = {
      selectedPayment,
      sourceApp: params.sourceApp,
      waysOfPay: {
        typesIncludes: [],
        numberOfMethods: 0
      },
    };
  }
}

