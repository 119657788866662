import { CheckinHttpClient } from 'src/app/shared/services/http/http-client.service';
import { EnvService } from './env.service';
import { of } from 'rxjs';
import { map, tap, catchError } from 'rxjs/operators';
import { phoneCodes } from '../components/inputs/input-phone/input-phone.codes';
import { InjectorService } from 'src/app/shared/services/injector/injector.service';
var GeoService = /** @class */ (function () {
    function GeoService(env) {
        this.env = env;
        this.http = InjectorService.get().get(CheckinHttpClient);
        this.baseUrl = this.env.endpoint + "/v1/";
    }
    GeoService.prototype.countries = function () {
        if (this.countriesValue) {
            return of(this.countriesValue);
        }
        if (!this.countriesRequest) {
            this.countriesRequest = this._getCountriesRequestWithCb(function (countries) { return countries; });
        }
        return this.countriesRequest;
    };
    GeoService.prototype.prefixes = function () {
        var _this = this;
        if (this.countriesValue) {
            return of(this._parsePrefixes());
        }
        if (!this.prefixesRequest) {
            this.prefixesRequest = this._getCountriesRequestWithCb(function () { return _this._parsePrefixes(); });
        }
        return this.prefixesRequest;
    };
    GeoService.prototype._getCountriesRequestWithCb = function (cb) {
        var _this = this;
        var url = this.baseUrl + "countries";
        return this.http.get(url).pipe(tap(function (res) {
            _this.countriesValue = _this.sortCountriesByPopular(res);
        }), map(function (res) { return cb(_this.countriesValue); }), catchError(function (error) {
            throw new Error(error || 'Server error');
        }));
    };
    GeoService.prototype._parsePrefixes = function () {
        var _this = this;
        return Object.keys(this.countriesValue).map(function (index) {
            var _a = _this.countriesValue[index], code = _a.code, name = _a.name, disabled = _a.disabled;
            return { code: phoneCodes[code] ? phoneCodes[code] : code, name: name, disabled: disabled };
        });
    };
    GeoService.prototype.sortCountriesByPopular = function (countries) {
        var popularCodes = { 1: true, 2: true, 5: true, 6: true, 8: true, 23: true, 26: true };
        var _a = countries.reduce(function (acc, c) {
            var isPopular = popularCodes[c.code];
            acc[isPopular ? 'popular' : 'rest'].push(c);
            return acc;
        }, { popular: [], rest: [] }), popular = _a.popular, rest = _a.rest;
        return popular.concat([
            { code: '', name: '─────────────────────', disabled: true }
        ], rest);
    };
    GeoService.prototype.getEntityByKey = function (value, entity, criteriaCondition) {
        // tslint:disable-next-line:max-line-length
        var url = (criteriaCondition) ? this.baseUrl + "georegions?query=" + value + "&fields=" + entity + "&criteria=" + criteriaCondition : this.baseUrl + "georegions?query=" + value + "&fields=" + entity;
        return this.http.get(url).pipe(map(function (res) { return res[entity].slice(0, 5); }), catchError(function (error) {
            return of([]);
        }));
    };
    return GeoService;
}());
export { GeoService };
