import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Observable, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { SelectDataOptionsType } from '../common.interfaces';
import { GeoService } from './geo.service';
import { pad } from '../utils/pad';
import get from 'lodash.get';
import { OccupantDocument } from 'src/app/shared/main/body/occupant-detail/occupant-identify/occupant-identify-documents/occupant-documents.enum';
import { ConfigService } from 'src/app/shared/services/config/config.service';
import { ETravelByOptions } from 'src/app/checkin-app/modules/checkin/pages/shared/travel-info/enums/travel-by-options.enum';
import { CheckinHttpClient } from 'src/app/shared/services/http/http-client.service';
import { InjectorService } from 'src/app/shared/services/injector/injector.service';
import { EnvService } from './env.service';
import { convertTimeToAmPm } from 'src/app/shared/common/utils/dates';
import { EKinshipOptions } from 'src/app/checkin-app/modules/checkin/pages/wizard/pages/form/enums/kinship.enum';

const SERVICES_MAP = {
  countries: 'countries',
  documentType: 'documentTypeSelector',
  expiryMonth: 'expiryMonthSelector',
  expiryYear: 'expiryYearSelector',
  gender: 'genderSelector',
  hour: 'hour',
  arrivalHour: 'arrivalHour',
  departureHour: 'departureHour',
  prefixes: 'prefixes',
  travelBy: 'travelBy',
  clientKinship: 'clientKinship',
  typeOfGuest: 'typeOfGuestSelector',
  idealPaymentMethodBankOptions: 'idealPaymentMethodBankOptions',
  sofortPaymentMethodBankOptions: 'sofortPaymentMethodBankOptions'
};

@Injectable()
export class SelectDataService {
  private httpClient: CheckinHttpClient;

  constructor(
    private env: EnvService,
    private config: ConfigService,
    private geoService: GeoService,
    private translateService: TranslateService
  ) {
    this.httpClient = InjectorService.get().get(CheckinHttpClient);
  }

  get(service: string, option?: string): Observable<SelectDataOptionsType[]> {
    if (SERVICES_MAP[service] && this[SERVICES_MAP[service]]) {
      // Even if we don't use this translation, this way we securely can call `.instant`
      return this.translateService.get('data-service-init').pipe(
        switchMap(() => {
          return this[SERVICES_MAP[service]](option) as Observable<SelectDataOptionsType[]>;
        })
      );
    }

    console.warn(`service-data: Service ${service || '<no service>'} not found`);
    return of([]);
  }

  countries(): Observable<SelectDataOptionsType[]> {
    return this.geoService.countries();
  }

  prefixes(): Observable<SelectDataOptionsType[]> {
    return this.geoService.prefixes();
  }

  typeOfGuestSelector(): Observable<SelectDataOptionsType[]> {
    return of([
      { name: this.translateService.instant('travelling-alone'), code: 1 },
      { name: this.translateService.instant('family'), code: 2 },
      { name: this.translateService.instant('group'), code: 3 }
    ]);
  }

  documentTypeSelector(nationality: string): Observable<SelectDataOptionsType[]> {
    const { country: { code: countryCode } } = this.config.booking.acc;
    const spanish: boolean = (String(nationality) === '1');

    const options = spanish ?
      [{ name: this.translateService.instant(OccupantDocument.D), code: 'D' }] :
      [{ name: this.translateService.instant(OccupantDocument.I), code: 'I' }];

    options.push({ name: this.translateService.instant(OccupantDocument.P), code: 'P' });

    if (countryCode === 'ES' && !spanish) {
      options.push(...[
        { name: this.translateService.instant(OccupantDocument.N), code: 'N' },
        { name: this.translateService.instant(OccupantDocument.X), code: 'X' }
      ]);
    }

    return of(options);
  }

  genderSelector(): Observable<SelectDataOptionsType[]> {
    return of([
      { name: this.translateService.instant('female'), code: 'F' },
      { name: this.translateService.instant('male'), code: 'M' },
    ]);
  }

  travelBy(): Observable<SelectDataOptionsType[]> {
    return of([
      { name: this.translateService.instant('plane'), code: ETravelByOptions.PLANE },
      { name: this.translateService.instant('car'), code: ETravelByOptions.CAR },
      { name: this.translateService.instant('train'), code: ETravelByOptions.TRAIN },
      { name: this.translateService.instant('bus'), code: ETravelByOptions.BUS },
      { name: this.translateService.instant('boat'), code: ETravelByOptions.BOAT }
    ]);
  }

  clientKinship(): Observable<SelectDataOptionsType[]> {
    return of([
      { name: this.translateService.instant('grandpa-grandma'), code: EKinshipOptions.GRANDPA_GRANDMA },
      { name: this.translateService.instant('great-grandpa-grandma'), code: EKinshipOptions.GREAT_GRANDPA_GRANDMA },
      { name: this.translateService.instant('great-grandson-granddaughter'), code: EKinshipOptions.GREAT_GRANDSON_GRANDDAUGHTER },
      { name: this.translateService.instant('brother-sister-in-law'), code: EKinshipOptions.BROTHER_SISTER_IN_LAW },
      { name: this.translateService.instant('spouse'), code: EKinshipOptions.SPOUSE },
      { name: this.translateService.instant('son-daughter'), code: EKinshipOptions.SON_DAUGHTER },
      { name: this.translateService.instant('brother-sister'), code: EKinshipOptions.BROTHER_SISTER },
      { name: this.translateService.instant('grandson-granddaughter'), code: EKinshipOptions.GRANDSON_GRANDDAUGHTER },
      { name: this.translateService.instant('father-mother'), code: EKinshipOptions.FATHER_MOTHER },
      { name: this.translateService.instant('nephew-niece'), code: EKinshipOptions.NEPHEW_NIECE },
      { name: this.translateService.instant('father-mother-in-law'), code: EKinshipOptions.FATHER_MOTHER_IN_LAW },
      { name: this.translateService.instant('uncle-aunt'), code: EKinshipOptions.UNCLE_AUNT },
      { name: this.translateService.instant('son-daughter-in-law'), code: EKinshipOptions.SON_DAUGHTER_IN_LAW },
      { name: this.translateService.instant('tutors'), code: EKinshipOptions.TUTORS },
      { name: this.translateService.instant('other'), code: EKinshipOptions.OTHER }
    ]);
  }

  expiryMonthSelector(): Observable<SelectDataOptionsType[]> {
    const months = [];

    for (let i = 1; i <= 12; i++) {
      const num = pad(i, 2);
      months.push({ name: num, code: num });
    }

    return of(months);
  }

  expiryYearSelector(): Observable<SelectDataOptionsType[]> {
    const months = [];
    const year = (new Date().getFullYear());

    for (let i = year; i <= (year + 10); i++) {
      months.push({ name: String(i), code: i });
    }

    return of(months);
  }

  private generateHours(shouldInsertFn?: (s: string) => boolean, splitHours?: boolean) {
    if (!shouldInsertFn) {
      shouldInsertFn = (s: string) => true;
    }

    const result = [];
    let prevInserted = true; // Previous element was inserted, not skipped
    let insertedSplitter = true; // The last element is a splitter

    for (let i = 0; i <= 24; i++) {
      for (let z = 0; z <= (splitHours ? 45 : 1); z += 15) {
        let hour = `${pad(i, 2)}:${pad(z, 2)}`;
        if (i === 24) {
          if (z > 0) {
          continue;
          }
          prevInserted = false;
          if (shouldInsertFn(hour)) {
            hour = '00:00';
            result.push({ code: hour, name: hour });
            prevInserted = true;
            insertedSplitter = false;
          }
        } else {
          prevInserted = false;
          if (shouldInsertFn(hour)) {
            result.push({ code: hour, name: hour });
            prevInserted = true;
            insertedSplitter = false;
          }
        }

        if (!prevInserted && !insertedSplitter) {
          insertedSplitter = true;
          result.push({ code: '-', name: '-', disabled: true });
        }
      }
    }

    const lastElem = result[result.length - 1];
    if (lastElem && lastElem.disabled) {
      result.pop();
    }

    if (this.config.locale.get() === 'en_US') {
      result.map(value => {
        value.name = convertTimeToAmPm(value.name);
        return value;
      });
    }

    return result;
  }

  hour(): Observable<SelectDataOptionsType[]> {
    return of(this.generateHours((s: string) => true, true));
  }

  arrivalHour(): Observable<SelectDataOptionsType[]> {
    const { arrivalFormInfo } = this.config.booking;
    const times = get(arrivalFormInfo, 'penalties.times');
    const extraAmount = get(arrivalFormInfo, 'penalties.extraAmount', null);

    if (times) {
      const hours = this.generateHours((hour) => {
        return times.some(({ from, to }) => {
          to = to === '00:00' ? '24:00' : to;

          return (from <= hour && hour <= to) || (extraAmount && hour > to);
        });
      }, this.splitHours(times));
      return of(hours);
    }

    return of(this.generateHours());
  }

  departureHour(): Observable<SelectDataOptionsType[]> {
    const { arrivalFormInfo } = this.config.booking;
    const max = get(arrivalFormInfo, 'checkOut.max', '24:00');
    const hours = this.generateHours((hour) => {
      const [mHour, mMin] = max.split(':');

      return hour <= `${pad(mHour, 2)}:${pad(mMin, 2)}`;
    }, this.splitHours(max));
    return of(hours);
  }

  private splitHours(value: any): boolean {
    const validate = (min: string) => min !== '00';

    if (Array.isArray(value)) {
      return value.filter(({ from, to }) => {
        const [, fMin] = from.split(':');
        const [, tMin] = to.split(':');

        return validate(fMin) || validate(tMin);
      }).length > 0;
    }

    if (typeof value === 'string') {
      const [, mMin] = value.split(':');
      return validate(mMin);
    }
  }

  public idealPaymentMethodBankOptions(): Observable<any> {
    return this.httpClient.get(`${this.env.endpoint}/v2/payments/ideal/bank-options`);
  }

  public sofortPaymentMethodBankOptions(): Observable<any> {
    return this.httpClient.get(`${this.env.endpoint}/v2/payments/sofort/country-options`);
  }
}
